[data-sidebar="prime"] {
  .sidebar {
    background-color: $primary;
    @include gradient-y(desaturate(darken($primary, 15%), 10%), $primary);

    &::before {
      background-color: rgba(darken($primary, 5%), .75);
    }

    .sidebar-logo {
      color: #fff;
    }

    .sidebar-header {
      border-bottom-color: rgba(#fff, .12);
    }

    .sidebar-body {
      .nav-group {
        border-bottom-color: rgba(#fff, .12);
      }

      .nav-label {
        color: rgba(#4C9C2E, .45);
      }

      >.ps__rail-y {
        background-color: rgba(#fff, .1);

        >.ps__thumb-y {
          background-color: rgba(#fff, .75);
        }
      }
    }

    .nav-sidebar {
      .nav-link {
        color: rgba(#fff, .6);

        &:hover,
        &:focus {
          color: #fff;
        }

        &.has-sub::after {
          color: rgba(#fff, .6);
        }

        &.has-sub.active {
          color: #fff;
        }

        &.active {
          color: #fff;
          font-weight: 400;
        }
      }

      .nav-sub-link {
        color: rgba(#fff, .5);

        &::before {
          background-color: rgba(#fff, .45);
        }

        &.active {
          color: #fff;
        }
      }
    }
  }

  .sidebar-footer {
    background-color: $primary;
    border-top-color: rgba(#fff, .12);
    box-shadow: none;

    .dropdown-link {
      color: rgba(#fff, .6);

      &:hover,
      &:focus {
        background-color: darken($primary, 3%);
        color: #fff;
      }
    }
  }

  .sidebar-footer-body {
    h6 a {
      color: #fff;
    }

    p {
      color: rgba(#fff, .6);
    }
  }

  .sidebar-footer-menu {
    a {
      color: rgba(#fff, .6);

      &:hover,
      &:focus {
        background-color: darken($primary, 3%);
        color: #fff;
      }
    }

    hr {
      background-color: #fff;
      opacity: .1;
    }
  }
}
.input-icon {
    position: relative;
  }
  
  .input-icon .icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: darkblue;
    font-size: 1.3rem; /* Ajusta el tamaño del icono según tus preferencias */
    pointer-events: none; /* Evita que el icono sea clickeable */
  }
  
  .input-icon input {
    padding-left: 30px; /* Ajusta el espacio para que el icono esté dentro de la caja de texto */
    background-color: #f2f2f2; /* Cambia el color de fondo a gris */

  }
  
  .input-icon input::placeholder {
    font-size: 1.5rem; /* Ajusta el tamaño de la fuente del placeholder según tus preferencias */
    color: #e21212; /* Cambia el color del placeholder a gris oscuro (#444) o al color deseado */
    text-align: center; /* Alinea el texto del placeholder al centro */

  }
  
  .custom-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

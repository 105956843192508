.custom-card:hover {
    background-color: #0A1B61;
    border-color: #0A1B61;
    color: white;
    cursor: pointer;
    transform: scale(1.05);
  }
  
  .card-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card:hover .card-image-wrapper img .card-text {
    filter: brightness(0) invert(1);

    color: white;

  }

  /*PARTE QUE HACE QUE LAS CARDS SE UBIQUEN POR ENCIMA DE LAS IMAGENES*/
  .card-wrapper {
    position: relative;
    bottom: 50px; 
  }

  .overlay-card {
    position: relative;
    z-index: 1;
  }

 
  
  .carousel {
    position: relative;
    z-index: 0;
  }
  /*///////////////////////////////////////////////////////////////////*/

  .card:hover .card-text {
    color: white;

  }

  .card:hover .img{
    filter: brightness(0) invert(1);

  }
  
  .card-text {
    color: green;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button-support {
    background-color: white;
    color: darkgreen;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .button-support:hover {
    background-color: #0A1B61 !important;
    border-color: #0A1B61 !important;
    color: white !important;
  }

  .card-custom {
    width: 15rem;
    margin: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-body {
    flex-grow: 1;
  }



  .card-container {
    height: 500px; /* Ajusta la altura según tus necesidades */
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .card-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .custom-card {
    transition: transform 0.3s;
  }
  
  .custom-card:hover {
    transform: scale(1.2);
    /* Otros estilos adicionales que desees aplicar al hacer hover */
  }

  
  
.footer {
  background-color: #4c9c2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px; /* Ajusta el espacio superior */
  position: relative; /* Agrega posición relativa al footer */
  left: 0;
  bottom: 0;
  width: 100%;
}

.rectangle {
  background-color: lightgrey;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px; /* Ajusta el espacio entre elementos */
  margin: -100px auto 0; /* Ajusta la posición del rectángulo */
  max-width: 80%; /* Ajusta el ancho máximo del rectángulo */
  width: 70%; /* Ajusta el ancho del rectángulo */
  height: 80px; /* Ajusta la altura del rectángulo */
}

@media (max-width: 768px) {
  /* Estilos para pantallas más pequeñas */
  .rectangle {
    height: 50px;
    width: 120%; /* Ajusta el ancho del rectángulo */
  }
}

@media (max-width: 480px) {
  /* Estilos para pantallas aún más pequeñas */
  .rectangle {
    height: 50px;
    width: 80%; /* Ajusta el ancho del rectángulo */
    grid-template-columns: 1fr; /* Cambia a una sola columna */
  }
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 100%;
  height: auto;
}

.footer-text {
  text-align: center;
  text-justify: auto;
  font-size: small;
  color: darkblue;
}

.footerAbajo {
  display: flex;
  justify-content: space-between;
}

.footerAbajo .column {
  flex-basis: 33%;
}

.footerAbajo .column label {
  color: white;
  margin-top: -15px; /* Ajusta el valor según sea necesario */
}

.footerAbajo .column .circle-image {
  margin-bottom: 10px; /* Ajusta el valor según sea necesario */
  margin-top: -15px; /* Ajusta el valor según sea necesario */
}

/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

 code[class*="language-"],
 pre[class*="language-"] {
     color: black;
     background: none;
     text-shadow: 0 1px white;
     font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
     font-size: 1em;
     text-align: left;
     white-space: pre;
     word-spacing: normal;
     word-break: normal;
     word-wrap: normal;
     line-height: 1.5;
 
     -moz-tab-size: 4;
     -o-tab-size: 4;
     tab-size: 4;
 
     -webkit-hyphens: none;
     -moz-hyphens: none;
     -ms-hyphens: none;
     hyphens: none;
 }
 
 pre[class*="language-"]::-moz-selection,
 pre[class*="language-"] ::-moz-selection,
 code[class*="language-"]::-moz-selection,
 code[class*="language-"] ::-moz-selection {
     text-shadow: none;
     background: #b3d4fc;
 }
 
 pre[class*="language-"]::selection,
 pre[class*="language-"] ::selection,
 code[class*="language-"]::selection,
 code[class*="language-"] ::selection {
     text-shadow: none;
     background: #b3d4fc;
 }
 
 @media print {
     code[class*="language-"],
     pre[class*="language-"] {
         text-shadow: none;
     }
 }
 
 /* Code blocks */
 pre[class*="language-"] {
     padding: 1em;
     margin: 0;
     overflow: auto;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
     background: var(--#{$prefix}light) !important;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
     padding: 0.1em;
     border-radius: 0.3em;
     white-space: normal;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
     color: slategray;
 }
 
 .token.punctuation {
     color: #999;
 }
 
 .token.namespace {
     opacity: 0.7;
 }
 
 .token.property,
 .token.tag,
 .token.boolean,
 .token.number,
 .token.constant,
 .token.symbol,
 .token.deleted {
     color: $danger;
 }
 
 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
     color: #690;
 }
 
 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string {
     color: #9a6e3a;
     /* This background color was intended by the author of this theme. */
     background: hsla(0, 0%, 100%, 0.5);
 }
 
 .token.atrule,
 .token.attr-value,
 .token.keyword {
     color: #07a;
 }
 
 .token.function,
 .token.class-name {
     color: #dd4a68;
 }
 
 .token.regex,
 .token.important,
 .token.variable {
     color: #e90;
 }
 
 .token.important,
 .token.bold {
     font-weight: bold;
 }
 .token.italic {
     font-style: italic;
 }
 
 .token.entity {
     cursor: help;
 }
 
 /* Prism editor */
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
     background: #000000;
 }
 
 code[class*="language-"],
 pre[class*="language-"] {
     color: #000000;
     text-shadow: none;
 }
 
 
 
 // Scrollbar
 
 .language-markup {
     &::-webkit-scrollbar {
         -webkit-appearance: none;
     }
 
     &::-webkit-scrollbar:vertical {
         width: 10px;
     }
 
     &::-webkit-scrollbar:horizontal {
         height: 10px;
     }
 
     &::-webkit-scrollbar-thumb {
         background-color: rgba(var(--#{$prefix}dark-rgb), .1);
         border-radius: 10px;
         border: 2px solid var(--#{$prefix}light)
     }
 
     &::-webkit-scrollbar-track {
         border-radius: 10px;
         background-color: var(--#{$prefix}light)
     }
 }
 
 
 // Scroll-top
 
 #scroll-top {
     width: 30px;
     height: 30px;
     position: fixed;
     bottom: 65px;
     right: 30px;
     background: #343a40;
     border-color: transparent;
     border-radius: 3px;
     color: #ffffff;
     transition: all 0.5s ease;
 }
 
 // Toolbar (Copy button)
 
 .btn-clipboard {
     position: absolute !important;
     right: 15px !important;
     z-index: 1 !important;
 }
 
 div.code-toolbar > .toolbar {
     opacity: 1 !important;
     button {
         display: inline-block !important;
         margin: 0.375rem 0.5rem !important;
         padding: 0.25rem 0.75rem !important;
         transition: $btn-transition !important;
         border-radius: $border-radius-sm !important;
         border: $border-width solid rgba($primary, 0.35) !important;
         background-color: transparent;
         color: $primary !important;
         box-shadow: none !important;
         &:focus {
             outline: none !important;
             box-shadow: none !important;
         }
         &:hover {
             background-color: $primary !important;
             color: $white !important;
         }
     }
 }
 